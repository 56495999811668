<template>
	<div class="Attendance_rules_set" v-loading="loading"> 

		<div class="head_top">
			<h4>薪资设置</h4>
		</div>
		<div class="bom_box">
			<div class="flex_box">
				<div class="lefts">
					<div class="titles">工资工龄：</div>
				</div>
				<div class="rights">
					<div class="inputs1">
						(本年-参加工作年份) * 
						<input type="number" name="" value="" v-model.number="form.sal_glgz" placeholder="请输入数字">
					</div>
				</div>
			</div>
			<div class="flex_box">
				<div class="lefts">
					<div class="titles">年休假工资：</div>
				</div>
				<div class="rights">
					<div class="inputs1">
						(定岗工资÷应出勤天数) * 年休假天数
					</div>
				</div>
			</div>
			<div class="flex_box">
				<div class="lefts">
					<div class="titles">节日工资：</div>
				</div>
				<div class="rights">
					<div class="inputs1">
						节日天数 * <input type="number" name="" value="" v-model.number="form.sal_jieri" placeholder="请输入数字">(元)
					</div>
				</div>
			</div>
			<div class="flex_box2">
				<div class="lefts">
					<div class="titles">女工补贴：</div>
				</div>
				<div class="rights">
					<div class="inputs1">
						<el-input type="number" v-model.number="form.sal_nvgong" placeholder="请输入女工补贴"></el-input>
						元
					</div>
				</div>
			</div>
			<div class="flex_box2">
				<div class="lefts">
					<div class="titles">高温补贴：</div>
				</div>
				<div class="rights">
					<div class="inputs2">
						 <div class="input_list" v-for="(item,index) in sal_gaowen" :key="index">
							<div class="item_inp1">
								<el-cascader placeholder="请选择" :options="gw_list" collapse-tags :props="props" :show-all-levels="false"
								v-model="item.gw_list" clearable></el-cascader>
							</div>
							<div class="item_inp2">
								<el-input type="number" v-model.number="item.price" placeholder="设置金额"></el-input>
						元 月/人
							</div>
							<div class="item_inp3">
								<!-- <div class="btn1"><el-button type="text">保存</el-button></div> -->
								<!-- <div class="btn1"><el-button type="text">编辑</el-button></div> -->
								<div class="btn1" v-if="index!=0"><el-button type="text" @click="handle_clear('sal_gaowen',index)">删除</el-button></div>
							</div>
						 </div>
						 <div class="list_add_btns">
							<div class="btns" @click="handle_add('sal_gaowen')">增加</div>
						 </div>
					</div>
				</div>
			</div>
			<div class="flex_box2">
				<div class="lefts">
					<div class="titles">生活补贴：</div>
				</div>
				<div class="rights">
					<div class="inputs2">
						 <div class="input_list" v-for="(item,index) in sal_shenghuo" :key="index">
							<div class="item_inp1">
								<!-- <el-select clearable v-model="item.gw_id" placeholder="岗位名称">
									<el-option v-for="(items,indexs) in bumen_list" :key="indexs" :label="items.title"
									:value="items.value">

									</el-option>
								</el-select> -->
								 
								<el-cascader placeholder="请选择" :options="gw_list" collapse-tags :props="props" :show-all-levels="false"
								v-model="item.gw_list" clearable></el-cascader>
							</div>
							<div class="item_inp2">
								<el-input type="number" v-model.number="item.price" placeholder="设置金额"></el-input>
								元 月/人
							</div>
							<div class="item_inp3">
								<!-- <div class="btn1"><el-button type="text">保存</el-button></div> -->
								<!-- <div class="btn1"><el-button type="text">编辑</el-button></div> -->
								<div class="btn1" v-if="index!=0"><el-button type="text" @click="handle_clear('sal_shenghuo',index)">删除</el-button></div>
							</div>
						 </div>
						 <div class="list_add_btns">
							<div class="btns" @click="handle_add('sal_shenghuo')">增加</div>
						 </div>
					</div>
				</div>
			</div>

			<div class="flex_box2">
				<div class="lefts">
					<div class="titles">下井补贴：</div>
				</div>
				<div class="rights">
					<div class="inputs1">
						<el-input type="number" v-model.number="form.sal_xiajing" placeholder="请输入下井补贴"></el-input>
						元 * 次数
					</div>
				</div>
			</div>
			<div class="flex_box2">
				<div class="lefts">
					<div class="titles">下井带班补贴：</div>
				</div>
				<div class="rights">
					<div class="inputs1">
						<el-input type="number" v-model.number="form.sal_xiajingdaiban" placeholder="请输入下井带班补贴"></el-input>
						元 * 次数
					</div>
				</div>
			</div>
			<div class="flex_box2">
				<div class="lefts">
					<div class="titles">山区补助：</div>
				</div>
				<div class="rights">
					<div class="inputs2">
						 <div class="input_list" v-for="(item,index) in sal_shanqu" :key="index">
							<div class="item_inp1">
								<!-- <el-cascader placeholder="请选择" :options="gw_list" collapse-tags :props="props" :show-all-levels="false"
								v-model="item.gw_list" clearable></el-cascader> -->
								<el-select v-model="item.gw_list" placeholder="请选择">
									<el-option
									v-for="(item,index) in kqj_list"
									:key="index"
									:label="item.title"
									:value="item.id">
									</el-option>
								</el-select>
								
							</div>
							<div class="item_inp2">
								<el-input type="number" v-model.number="item.price" placeholder="设置金额"></el-input>
								元 月/人
							</div>
							<div class="item_inp3">
								<!-- <div class="btn1"><el-button type="text">保存</el-button></div> -->
								<!-- <div class="btn1"><el-button type="text">编辑</el-button></div> -->
								<div class="btn1" v-if="index!=0"><el-button type="text" @click="handle_clear('sal_shanqu',index)">删除</el-button></div>
							</div>
						 </div>
						 <div class="list_add_btns">
							<div class="btns" @click="handle_add('sal_shanqu')">增加</div>
						 </div>
					</div>
				</div>
			</div>
			<div class="flex_box2">
				<div class="lefts">
					
				</div>
				<div class="rights">
					<div class="inputs2">
						 <div class="btn_end">
							<div class="btns1" @click="handle_end">提交</div>
							<div class="btns2" @click="handle_end">取消</div>
						 </div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>
<script>
	import bumen_cascader from '@/components/bumen/bumen_cascader.vue'; //部门选择

	export default {
		components: {
			bumen_cascader
		},
		data() {
			return {
				loading:false,
				form:{
					sal_glgz:'',//工资年龄基数
					sal_year:'',//年假（暂时无用）
					sal_jieri:'',//节日补贴
					sal_nvgong:'',//女工补贴
					sal_gaowen:'',//高温补贴josn的list
					sal_shenghuo:'',//生活补贴josn的list
					sal_xiajing:'',//下井补贴
					sal_xiajingdaiban:'',//下井带班补贴
					sal_shanqu:'',//山区补贴josn的list
				},
				kqj_list:[],
				sal_gaowen:[
					{
						gw_id:'',
						price:0,
						gw_list:[],
					}
				],//高温补贴
				sal_shenghuo:[
					{
						gw_id:'',
						price:0,
						gw_list:[],
					}
				],//生活补贴
				sal_shanqu:[
					{
						gw_id:'',
						price:0,
						gw_list:[],
					}
				],//山区补贴
				props: {
					multiple: false,
					value: 'id',
					label: 'title'
				},
				gw_list:[],//岗位列表
			};
		},
		computed: {},
		watch: {},
		created() {
			this.get_setting()
			this.get_gw_list()
			this.get_kqj_list()
		},
		mounted() {},
		methods: {
			get_kqj_list(){
				this.loading = true
				this.$api("kaoqin/getMachineList", {
					 
				}, "get").then((res) => {
					this.loading = false
					console.log('获取薪资配置',res)
					if (res.code == 200) {
						this.kqj_list=res.data
					} else {
						alertErr(res.msg)
					}
				})
			},
			get_setting(){
				this.loading = true
				this.$api("getSalarySetting", {
					 
				}, "post").then((res) => {
					this.loading = false
					console.log('获取薪资配置',res)
					if (res.code == 200) {
						this.form=res.data
						if(res.data.sal_gaowen){
							this.sal_gaowen=JSON.parse(res.data.sal_gaowen)
						}
						if(res.data.sal_shenghuo){
							this.sal_shenghuo=JSON.parse(res.data.sal_shenghuo)
						}

						if(res.data.sal_shanqu){
							this.sal_shanqu=JSON.parse(res.data.sal_shanqu)
						}
						
					} else {
						alertErr(res.msg)
					}
				});
			},
			handle_clear(form_text,i){
				this[form_text].splice(i,1)
			},
			handle_add(form_text){
				this[form_text].push({
					gw_id:'',
					price:0,
					gw_list:[],

				})

			},
			//获取岗位
			get_gw_list() {
				this.loading = true
				this.$api("getCategoryAllList", {

				}, "get").then((res) => {
					console.log('所有的岗位', res)
					this.loading = false
					if (res.code == 200) {
						let level = 0
						let list = []
						let data = res.data
						data.forEach((e, i) => {
							if (e.level > level) {
								level = e.level
							}
							this.$set(e, 'children', [])
						})
						console.log('最高登记', level)

						for (let i = 1; i <= level; i++) {
							let level_list = []
							data.forEach((e) => {
								if (e.level == i) {
									level_list.push(e)
								}
							})
							list.push(level_list)
						}
						console.log('列表', list)
						list[list.length - 1].forEach((e) => {
							this.$delete(e, 'children')
						})
						// 总列表  最后一位的下标
						this.gw_list_data(list, list.length - 1)



					} else {
						alertErr(res.msg)
					}
				});
			},
			//处理岗位列表
			gw_list_data(lists, indexs) {
				let index1 = indexs //当前下标
				let index2 = indexs - 1 //上级下标
				let list = lists
				list[index1].forEach((e) => {
					list[index2].forEach((v) => {
						if (v.id == e.parent_id) {
							v.children.push(e)
						}
					})
				})
				list[index2].forEach((e) => {
					if (e.children.length == 0) {
						this.$delete(e, 'children')
					}
				})
				if (index2 == 0) {
					console.log('最后的数据', list)
					this.gw_list = list[0]
					return
				}
				this.gw_list_data(list, index2)
			},
		 
			// 确认
			handle_end(){
				if(!this.form.sal_glgz){
					alertErr('请输入工资年龄基数')
					return
				}
				if(!this.form.sal_jieri){
					alertErr('请输入节日工资')
					return
				}
				if(!this.form.sal_nvgong){
					alertErr('请输入女工补贴')
					return
				}
				for (let i = 0; i < this.sal_gaowen.length; i++) {
					const data = this.sal_gaowen[i];
					if(data.gw_list.length==0){
						alertErr(`请选择高温补贴的第${i+1}行的岗位`)

						return
					}else{
						data.gw_id=data.gw_list[data.gw_list.length-1]
					}
					if(data.price<0){
						alertErr(`高温补贴的第${i+1}行的金额必须大于0`)
						return
					}
					
				}
				for (let i = 0; i < this.sal_shenghuo.length; i++) {
					const data = this.sal_shenghuo[i];
					if(data.gw_list.length==0){
						alertErr(`请选择生活补贴的第${i+1}行的岗位`)

						return
					}else{
						data.gw_id=data.gw_list[data.gw_list.length-1]
					}
					if(data.price<0){
						alertErr(`生活补贴的第${i+1}行的金额必须大于0`)
						return
					}
					
				}
				if(!this.form.sal_xiajing){
					alertErr('请输入下井补贴')
					return
				}
				if(!this.form.sal_xiajingdaiban){
					alertErr('请输入下井带班补贴')
					return
				}

				for (let i = 0; i < this.sal_shanqu.length; i++) {
					const data = this.sal_shanqu[i];
					if(data.gw_list.length==0){
						alertErr(`请选择山区补助的第${i+1}行的岗位`)
						return
					}else{
						data.gw_id=data.gw_list[data.gw_list.length-1]
					}
					if(data.price<0){
						alertErr(`山区补助的第${i+1}行的金额必须大于0`)
						return
					}
					
				}
				this.form.sal_gaowen=JSON.stringify(this.sal_gaowen)
				this.form.sal_shenghuo=JSON.stringify(this.sal_shenghuo)
				this.form.sal_shanqu=JSON.stringify(this.sal_shanqu)
				console.log('form',this.form)
				this.ends()

			},
			ends(){
				this.loading = true
				this.$api("editSalarySetting", {
					 ...this.form
				}, "post").then((res) => {
					this.loading = false
					console.log('更新薪资配置',res)
					if (res.code == 200) {
						alertSucc('提交成功')
					} else {
						alertErr(res.msg)
					}
				});
			},

			 
		 

			 
			 

		 

		}
	};
</script>

<style scoped lang="less">
.Attendance_rules_set{
	background: #fff;
	border-radius: 8px 8px 8px 8px;
	min-height: calc(100vh);
	.head_top{
		padding: 20px 17px;
		h4 {
			font-weight: bold;
			font-size: 14px;
			color: #333333;
		}
	}
	.bom_box{
		padding: 35px;
		.titles{
			&:before {
				display: inline-block;
				content: '*';
				padding-right: 5px;
				text-align: right;
				color: red;
				// line-height: 40px;
			}
		}
		.flex_box{
			display: flex;
			align-items: center;
			margin-bottom: 38px;
			.lefts{
				width: 130px;
				.titles{
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;
					text-align: right;
				}
			}
			.rights{							
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #333333;
				padding-left: 24px;

				.inputs1{
					display: flex;
					align-items: baseline;
					input{
						width: 100px;
						height: 40px;
						border-radius: 5px;
						padding: 0 5px;
						border: 1px solid #D4D4D4;
						margin-right: 10px;
					}

				}
			}
		}
		.flex_box2{
			display: flex;
			// align-items: center;
			margin-bottom: 38px;
			.lefts{
				width: 130px;
				.titles{
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;
					text-align: right;
					line-height: 40px;
				}
			}
			.rights{							
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: 400;
				font-size: 14px;
				color: #333333;
				padding-left: 24px;
				.inputs1{
					display: flex;
					align-items: baseline;
					/deep/.el-input{
						width: 335px;
						height: 40px;
						margin-right: 13px;
						
					}

				}
				.inputs2{
					.input_list{
						display: flex;
						align-items: baseline;
						margin-bottom: 18px;
						.item_inp1{
							margin-right: 16px;
							/deep/.el-select{
								width: 335px;
								height: 40px;
							}
							/deep/.el-cascader{
								width: 335px;
								height: 40px;
							}
						}
						.item_inp2{
							display: flex;
							align-items: center;
							/deep/.el-input{
								width: 335px;
								height: 40px;
								margin-right: 16px;
								
							}
						}
						.item_inp3{
							display: flex;
							align-items: center;
							margin-left: 51px;
							.btn1{
								margin-right: 25px;
							}
						}
					}
					.list_add_btns{
						.btns{
							width: 99px;
							height: 44px;
							border-radius: 4px 4px 4px 4px;
							border: 1px solid #9D9D9D;

							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 16px;
							color: #333333;
							text-align: center;
							line-height: 44px;
							cursor: pointer;
							// margin-top: 27px;
						}
					
					}
					.btn_end{
						display: flex;
						align-items: center;
						.btns1{
							width: 179px;
							height: 44px;
							background: #2373C8;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 16px;
							color: #FFFFFF;
							text-align: center;
							line-height: 44px;
							cursor: pointer;
						}
						.btns2{
							margin-left: 18px;
							width: 179px;
							height: 44px;
							border-radius: 4px 4px 4px 4px;
							border: 1px solid #9D9D9D;
							font-weight: 400;
							font-size: 16px;
							color: #4A4A4A;
							text-align: center;
							line-height: 44px;
							cursor: pointer;
						}
					}
				}
			}
			
		}
	}
}
 
</style>